import React from "react";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import baileyImg from "../../static/logos/baileyloveless.jpg";
import {ReactComponent as SocialLink} from "../assets/social-link.svg";
import {ReactComponent as PodcastTwitter} from "../assets/podcastTwitter.svg";
import {ReactComponent as PodcastInstagram} from "../assets/podcastInstagram.svg";
import pressKit from "../assets/Inn-Between-Press-Kit.pdf";

const AboutPage = () => {
  return (
    <Layout>
      <section className="about-container cc-container">
        <Helmet title={`About | ${config.siteTitle}`} />
        <SEO />
        <div className="cc-header cc-card cc-text">
          <div className="cc-padding cc-intro">
            <p className="my-0">Welcome, weary travelers, to The Inn Between, a bi-weekly, scripted podcast, where the trees are green, the rooms are cozy, and the guests are a little bit strange. Join Gabriela Jones, a recent botany undergrad who takes a job as Innkeeper near the rural town of Shearwater. <br /><br />
            <p>There are three rules:</p>
            <ol>
              <li>Keep out of the study</li>
              <li>Always trust the cat</li>
  		        <li>Never ever disturb the guests</li>
            </ol>
            New episodes <a href="https://www.timeanddate.com/moon/phases/">every quarter moon</a>.</p> 
            <p>Become a patron and <a href="https://ko-fi.com/theinnbetweenpodcast">visit our support page</a>.</p>
            <p>Press kit <a href={pressKit}>here</a>.</p>
          </div>
          <div className="cc-padding cc-portrait">
            <div className="img-wrapper">
              <img className="img-portait" src={baileyImg} alt="bailey loveless"/>
            </div>
            <div className="desc-wrapper">
            <div className="personal-links">
                <a href="https://www.instagram.com/bailey.loveless">
                  <PodcastInstagram/>
                </a>
                <a href="https://twitter.com/baileysloveless">
                  <PodcastTwitter/>
                </a>
                <a href="https://www.baileyloveless.com">
                  <SocialLink/>
                </a>
              </div>
              <div className="description">
                <p>Bailey lives in between the land and the sea, writing weird tales about animals, monsters, the feminine, the sublime, and the strangest of all creatures, mankind. She is currently a student at University of Washington. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
